<script>
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/PageHeader";
import appConfig from "../../../app.config";

import TermsConditionsUa from "./_TermsConditionsUa.vue";

export default {
  page: {
    title: "Privacy policy",
    meta: [{ name: "description", content: appConfig.description }],
  },
  data() {
    return {
      title: "Article",
      data: {
        article: {},
        blog: {}
      }
    };
  },
  components: {
    Layout,
    PageHeader,
    TermsConditionsUa,
  },
  created: function () {
    this.loadingArticle();
  },
  methods: {
    loadingArticle() {
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader title="Terms & Conditions" v-if="true" />

    <TermsConditionsUa></TermsConditionsUa>

  </Layout>
</template>